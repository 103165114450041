import { reasons, store, index } from "@/api/vacation";

export const actions = {
    index({ commit }, params = {}) {
        return new Promise((resolve, reject) => {
            index(params)
                .then(res => {
                    commit("SET_LIST", res.data.result.data.vacation);
                    // commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                    resolve(res);
                })
                .catch(err => {
                  console.log(err);
                    reject(err);
                });
        });
    },
    reasons({ commit }, params = {}) {
      return new Promise((resolve, reject) => {
          reasons()
              .then(res => {
                  commit("SET_REASON", res.data.result.data.reasons);
                  // commit("UPDATE_PAGINATION", { key: 'total', value: res.data.result.data.pagination.total });
                  resolve(res);
              })
              .catch(err => {
                console.log(err);
                  reject(err);
              });
      });
    },
    

    // inventory({ commit }, params = {}) {
    //     return new Promise((resolve, reject) => {
    //         inventory(params).then(res => {
    //             commit("SET_INVENTORY", res.data.result.data.branches);
    //             resolve(res.data)
    //         }).catch(err => {
    //             reject(err.response.data)
    //         })
    //     })
    // },

    // show({ commit }, id) {
    //     return new Promise((resolve, reject) => {
    //         show(id)
    //             .then(res => {
    //                 commit('SET_MODEL', res.data.result.data.branch);
    //                 resolve(res);
    //             })
    //             .catch(err => {
    //                 reject(err.response.data);
    //             });
    //     });
    // },

    store({ commit }, data) {
        return new Promise((resolve, reject) => {
            store(data)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },

    // update({ commit }, data) {
    //     return new Promise((resolve, reject) => {
    //         update(data)
    //             .then(res => {
    //                 resolve(res);
    //             })
    //             .catch(err => {
    //                 reject(err);
    //             });
    //     });
    // },

    empty({ commit }) {
        return new Promise((resolve, reject) => {
            commit("EMPTY_MODEL");
            resolve()
        })
    },

    destroy({ commit }, id) {
        return new Promise((resolve, reject) => {
            destroy(id)
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
    updateSort({ commit }, sort) {
        commit("SET_SORT", sort)
    },

    updateFilter({ commit }, filter) {
        commit('SET_FILTER', JSON.parse(JSON.stringify(filter)))
    },

    updateColumn({ commit }, obj) {
        commit('UPDATE_COLUMN', obj)
    },
    updatePagination({ commit }, pagination) {
        commit('UPDATE_PAGINATION', pagination)
    },
    refreshData({ commit }) {
        return new Promise((resolve, reject) => {
            commit("REFRESH");
            resolve()
        })
    },
};
