// export default router
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import { getToken } from "@/utils/auth";

Vue.use(VueRouter);

var routes = [
    {
  path: "/",
  name: "Home",
  meta: {
    layout: "main",
    public: false
  },
  component: Home
}];

/**
 * Load all routes from modulea folder
 */
const request = require.context("./modules", true, /.js/);

request.keys().map(module => {
  routes = routes.concat(request(module).default);
});

const router = new VueRouter({
  routes: routes, 
  mode: "history"
});

export default router;


// import Vue from "vue";
// import VueRouter from "vue-router";
// import Home from "../views/Home.vue";

// Vue.use(VueRouter);

// var routes = [{
//     path: "/",
//     name: "Home",
//     meta: {
//       layout: "main"
//     },
//     component: Home
//   },
//   {
//     path: '/login',
//     name: 'login',
//     meta: {
//       layout: 'empty'
//     },
//     component: () => import('./../views/login/index')
//   },
// ];

// /**
//  * Load all routes from modulea folder
//  */
// const request = require.context("./modules", true, /.js/);

// request.keys().map(module => {
//   console.log(module);
//   routes = routes.concat(request(module).default);
//   // debugger
// });

// const router = new VueRouter({
//   routes: routes,
//   // history:
//   // mode: "history"
// });

// export default router;

