import Layout from '@/layouts/EmptyLayout'
export default [
  {
    path: '/sick-leave',
    name: 'sick_leave_create',
    meta: {
      layout: 'main'
    },
  component: () => import('@/views/sickLeave/create')
    
  },
  {
    path: '/my-sick-leave',
    name: 'my_sick_leave',
    meta: {
      layout: 'main'
    },
  component: () => import('@/views/sickLeave/index')
    
  }
]
