import Layout from '@/layouts/EmptyLayout'
export default [{
    path: '/login2',
    name: 'login2',
    meta: {
      layout: 'empty'
    },
    component: () => import('@/views/login/login2')
  }]

