<template>
  <div class="theme-switch-wrapper">
    <label class="theme-switch" for="checkbox">
      <input type="checkbox" id="checkbox" />
      <i class="el-icon-turn-off"></i>
      <div class="name_text slider round"></div>
    </label>
  </div>
</template>
<script>
export default {
  name: "tema-dark",
  components: {},
  data() {
    return {
      value2: false
    };
  },
  mounted() {
    const toggleSwitch = document.querySelector(
      '.theme-switch input[type="checkbox"]'
    );
    const currentTheme = localStorage.getItem("theme");

    if (currentTheme) {
      document.documentElement.setAttribute("data-theme", currentTheme);

      if (currentTheme === "dark") {
        toggleSwitch.checked = true;
      }
    }

    function switchTheme(e) {
      if (e.target.checked) {
        document.documentElement.setAttribute("data-theme", "dark");
        localStorage.setItem("theme", "dark");
      } else {
        document.documentElement.setAttribute("data-theme", "light");
        localStorage.setItem("theme", "light");
      }
    }

    toggleSwitch.addEventListener("change", switchTheme, false);
  }
};
</script>
<style lang="scss" scoped>
.theme-switch-wrapper {
  width: 100%;
}
.theme-switch {
  // display: flex;
  // justify-content: space-between;
  padding: 12px 0px;
}
.tg-list {
  text-align: center;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.theme-switch-wrapper #checkbox {
  display: none;
}
</style>
