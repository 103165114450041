export const ru_messages = {
  n: "№",
  create: "Создать",
  settings: "Настройки",
  complaint: "Жалоба",
  avance: "Аванс",
  report_card: "Табель",
  additional_work: "Дополнительная работа",
  waiting: "Ожидающий",
  received: "Принято",
  denied: "Отказано",
  confirm: "Подтвердитъ",
  approved: "Одобренный",
  rejected: "Отклоненный",
  qty: "Kол-во",
  vacation: "Отпуск",
  my_requests: "Мои запросы",
  my_complaints: "Мои жалобы",
  permission_ask: "Попросить разрешения",
  my_permission_requests: "Мои запросы на разрешение",
  my_advance_requests: "Мои предварительные запросы",
  my_vacation_requests: "Мои запросы на отпуск",
  user: "Сотрудники",
  reason: "Причина",
  left_date: "Время ухода",
  come_date: "Дата прихода",
  status: "Статус",
  comment: "Объяснение",
  request_data: "Дата запроса",
  advance_amount: "Сумма аванса",
  back: "Назад",
  day: "День",
  hour: "Час",
  minut:'Минут',
  summa: "Сумма",
  information_come_towork : "Информация о том, пришел ли работник на работу или нет",
  today_working_hours: "Сегодняшнее рабочее время",
  total_working_hours: "Общее количество рабочих часов",
  total_working_days: "Всего рабочих дней",
  time: "Время",
  data_time: "Дата и время",
  from: "От",
  to: "До",
  manager: "Руководитель",
  title: "Тема",
  work_type: "Тип операции",
  count: "Число",
  confirm: "Подтверждение",
  whom_sent: "Кому отправлено",
  message_text: "Текст сообщения",
  sent_time: "Время отправки",
  ask_avance: "Предварительный запрос",
  ask_vacation: "Запрос отпуска",
  dedline: "Срок",
  send_complaint: "Отправить жалобу",
  details:'Подробности',
  evaluation:"Оценка",
  my_evaluation:"Мои оценки",
  evaluat:"Оценка",
  send_evaluation:"Сохранить рейтинг",
  created_at: "Дата создания",
  updated_at: "Дата изменения",
  name: "Наименование",
  my_bonuses: "Мои бонусы",
  amount: "Сумма",  
  my_penalties:"Мои штрафы",
  user_information:"Информация профиля",
  user_profile:"Профиль пользователя",
  limit_work_time:"Норма работы (час)",
  actual_working_time:"Фактическое время работы (час)",
  salary: "Зарплата",
  bonuse: "Бонусы",
  penalties:"Штрафы",
  requirement: "Требования",
  sick_leave:'Больничные',
  save:'Сохранять',
  my_sick_leave_requests:'Больничные',
  company: "Компания",
  branch_a: "Филиал",
  department: "Отдел",
  position: "Должность",
  accept_vacation_managment:"Месяц отпуска",
  not_attached:"Не прикреплен"
}
