import { pagination } from "@/store/modules/properties/pagination";
import { sort } from "@/store/modules/properties/sort";

export const state = {
    list: [],
    works: [],
    reasons:[],
    staff_list:[],
    staff_child:[],
    // inventory: [],
    // model: JSON.parse(JSON.stringify(model)),
    // columns: columns,
    // filter: filter,
    pagination: JSON.parse(JSON.stringify(pagination)),
    sort: JSON.parse(JSON.stringify(sort)),   
    // rules: rules   
};
