// import {getCurrentTab} from './../utils/local_storage'
export const state =  {
    money: {
        decimal: '.',
        thousands: ',',
        precision: 2,
        masked: false,
        prefix: '',
        suffix: ' сум',
    },
    // current_tab: getCurrentTab()
}
