<template>
  <el-dropdown szie="mini" @command="handleCommand">
    <span class="el-dropdown-link more_icons">
      <i class="el-icon-arrow-down el-icon-more-outline"></i>
    </span>
    <el-dropdown-menu slot="dropdown" size="mini">      
      <el-dropdown-item v-if="permissions.some(per => per.slug == (permissionShow)) && actions.some(action => action == 'edit') ? true : false" command="edit"   icon="el-icon-edit el-icon--left">       
        Изменить 
      </el-dropdown-item>
      <el-dropdown-item v-if="permissions.some(per => per.slug == (permissionDestroy)) && actions.some(action => action == 'delete')  ? true : false" command="delete" icon="el-icon-delete el-icon--left">
        Удалить
      </el-dropdown-item>
      <el-dropdown-item v-if="permissions.some(per => per.slug == (name+'@'+'assign_role')) && actions.some(action => action == 'assign_role')  ? true : false" command="assignRole"  icon="el-icon-edit el-icon--left">       
        Назначить роль 
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
  import {mapGetters} from "vuex";
  export default {
    props: {
      model: {
        type: Object,
        required: true
      },
      actions: {
        type: Array
      },
      name: {
        type: String,
        required: false
      },
      permissionShow:{
          type: String,
          required: false,
          default: 'AuthenticateController@login'
      },
      permissionDestroy:{
          type: String,
          required:false,
          default: 'AuthenticateController@login'
      }
    },
    data() {
      return {};
    },
    computed: {
      ...mapGetters({
        permissions: "auth/permissions"
      })
    },
    methods: {
      handleCommand(command) {
        if (command === "delete") {
          this.$confirm(
              "Вы действительно хотите это сделать?",
              "Предупреждение", {
                confirmButtonText: "Да",
                cancelButtonText: "Отмен",
                type: "warning"
              }
            )
            .then(() => {
              this.$emit(command, this.model);
            })
            .catch(() => {
              this.$message({
                type: "warning",
                message: "Операция отменена"
              });
            });
        } else {          
          this.$emit(command, this.model);
        }
      }, 
      
    }
  };

</script>
