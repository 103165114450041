export const getters = {
  list: state => state.list,
  works: state => state.works,
  staff_list: state=>state.staff_list,
  staff_child: state=>state.staff_child,
  reasons: state => state.reasons,
  model: state => state.model,
  rules: state => state.rules,
  columns: state => state.columns,
  filter: state => state.filter,
  pagination: state => state.pagination,
  sort: state => state.sort 
};
