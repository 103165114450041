<template>
    <div class="main">
        <div class="appLeft_layout">
            <Navbar></Navbar>
            <router-view />
        </div>
    </div>
</template>


<script>
import Navbar from "./components/header";
export default {
    components: { Navbar },
};
</script>
