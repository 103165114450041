import Layout from '@/layouts/EmptyLayout'
export default [
  {
    path: '/additionalWork',
    name: 'additionalWork',
    meta: {
      layout: 'main'
    },
  component: () => import('@/views/additionalWork/index')
    
  },
  {
    path: '/additionalWork/add',
    name: 'additionalWorkAdd',
    meta: {
      layout: 'main'
    },
  component: () => import('@/views/additionalWork/additionalWorkAdd')
    
  },

  {
    path: '/additionalWork/scanner',
    name: 'scanner',
    meta: {
      layout: 'main'
    },
  component: () => import('@/views/additionalWork/scanner')
    
  }
]
