<template>
  <div class="header-m-block">
    <div class="logo">
      <img src="./../../../public/img/gohrnew.png" />
    </div>
      <div class="header-left">
                
            
                  <div class="icons icons_header">
                    <el-dropdown class="flag fast__iconday"  trigger="click" @command="setLanguage">
                    <span class="el-dropdown-link">
                        <!-- <img src="/img/flag/russia.svg" alt class="flag_img" /> -->
                        <img
                        v-if="this.$i18n.locale == 'uz'"
                        src="/img/flag/uzbekistan.svg"
                        alt
                        class="flag_img"
                    />
                    <img
                        v-if="this.$i18n.locale == 'ru'"
                        src="/img/flag/russia.svg"
                        alt
                        class="flag_img"
                    />
                    <img
                        v-if="this.$i18n.locale == 'en'"
                        src="/img/flag/english.svg"
                        alt
                        class="flag_img"
                    />
                    <img
                        v-if="this.$i18n.locale == 'tr'"
                        src="/img/flag/turkey.svg"
                        alt
                        class="flag_img"
                    />
                        <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
                    </span>
                    <el-dropdown-menu   slot="dropdown" class="flag_ul">
                       <el-dropdown-item  command="uz" >
                          <img
                              src="/img/flag/uzbekistan.svg"
                              alt
                              class="flag_img flag_img2"
                          />
                          <span>Uzbek</span>
                      </el-dropdown-item>
                       <el-dropdown-item command="ru">
                            <img 
                                src="/img/flag/russia.svg"
                                alt
                                class="flag_img flag_img2"
                            />
                            <span>Russia</span>
                        </el-dropdown-item>
                      <el-dropdown-item command="tr" >
                        <img
                            src="/img/flag/turkey.svg"
                            alt
                            class="flag_img flag_img2"
                        />
                        <span>Turkey</span>
                      </el-dropdown-item>
                       <el-dropdown-item command="en" >
                        <img
                            src="/img/flag/english.svg"
                            alt
                            class="flag_img flag_img2"
                        />
                        <span>English</span>
                      </el-dropdown-item>
                     
                       
                    </el-dropdown-menu>
                </el-dropdown>
                    <a href="#">
                     <div class="fast__iconday " @click="fullscreen = !fullscreen">
                        <i class="el-icon-rank" id="full" ></i>
                      </div>
                    </a>
                    <!-- <i class="el-icon-moon"></i>
                    <i class="el-icon-news"></i> -->
                    <!-- {{staff}} -->
                    <router-link :to="{ name: 'profile' }">
                      <div class="fast__iconday user_name_icon">
                        {{ staff ? staff.name.slice(0,1) : '' }}
                      </div>
                    </router-link>
                    
                  </div>
    </div>
  </div>
</template>
<script>
import { getItem } from "@/utils/storage";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      fullscreen: false,
      system_language: localStorage.getItem('sys_language') ? localStorage.getItem('sys_language') : 'ru',
    };
  },
  mounted() {
    this.updateList(getItem("userId"));
  },
  computed: {
    ...mapGetters({
      staff:"assent/staff",
    }),
    fullscreenIcon() {
      if (this.fullscreen) {
        return "/fullscreen-exit.svg";
      } else {
        return "/fullscreen.svg";
      }
    },
   
  },
    watch: {
    fullscreen(enterFullscreen) {
      if (enterFullscreen) {
        var element = document.body;
        var requestMethod =
          element.requestFullScreen ||
          element.webkitRequestFullScreen ||
          element.mozRequestFullScreen ||
          element.msRequestFullScreen;

        if (requestMethod) {
          requestMethod.call(element);
        } else if (typeof window.ActiveXObject !== "undefined") {
          var wscript = new ActiveXObject("WScript.Shell");
          if (wscript !== null) {
            wscript.SendKeys("{F11}");
          }
        }
      } else {    
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          /* Firefox */
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          /* Chrome, Safari and Opera */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          /* IE/Edge */
          document.msExitFullscreen();
        }
      }
    },
   
  },
   methods: {
     ...mapActions({
      updateList: "assent/staffInfo",
    }),
      logErrors(promise) {
        promise.catch(console.error);
      },
      setLanguage(val) {
        localStorage.setItem('sys_language', val);
        this.$i18n.locale = val;
      },
    },
};
</script>

<style lang="scss">
.fast__iconday{
      display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: calc(1.5em + 1.5rem - 8px);
    width: calc(1.5em + 1.5rem - 8px);
    cursor: pointer;
    border: 1px solid #e8e5dd;
    font-weight: 500;
    line-height: 1.5;
    color: #323248;
    border-radius: 10px;
    text-decoration: none;
      color: #fff!important;
    font-weight: 700!important;
    
}
.icons_header{
  display: flex;
  align-items: center;
  gap: 7px;
}
.user_name_icon{
background: #0d67c9;
  
}
.header-left{
  display: flex;
  gap: 15px;
  align-items: center;
}
.flag {
    font-size: 16px !important;

    .el-dropdown-link {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .flag_img {
        width: 30px;
    }

    .el-icon--right {
        margin-left: 3px;
        font-size: 10px;
        color: #fff;
        margin-right: -2px;
    }
}
.flag_img2 {
    width: 31px;
}

.flag_ul {
    .el-dropdown-menu__item {
        border-bottom: 1px solid #f1f5f8;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .el-dropdown-menu__item img {
        margin-right: 5px;
    }
}

.flag_ul li:nth-child(2n + 2) {
    border-bottom: none;
}

</style>

