<template>
  <div>
    <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="placeholder || $t('message.account')"
      filterable
      clearable
      :size="size"
      class="d-block"
    >
      <el-option
        v-for="(account, index) in items"
        :key="'accounts-' + index"
        :label="account.name"
        :value="account.id"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    id: {
      default: null,
    },
    currency_id: {
      default: null,
    },
  },
  watch: {
    id: {
      handler: function () {
        setTimeout(() => {
          this.selected = this.id;
        });
      },
      immediate: true,
    },
    currency_id: {
      handler: function (newVal, oldVal) {
        if (newVal != oldVal) {
          this.selected = null;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  mounted() {
    if (this.accounts && this.accounts.length === 0) {
      this.updateInventory();
    }
  },
  computed: {
    ...mapGetters({
      accounts: "account/inventory",
    }),
    items: function () {
      return !this.currency_id
        ? this.accounts
        : _.filter(this.accounts, ["currency_id", this.currency_id]);
    },
  },
  methods: {
    ...mapActions({
      updateInventory: "account/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
  },
};
</script>
