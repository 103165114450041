import Layout from '@/layouts/EmptyLayout'
export default [{
    path: '/my-evaluation',
    name: 'my_evaluation',
    meta: {
      layout: 'main'
    },
  component: () => import('@/views/evaluation/my_evaluation')
    
  }]
