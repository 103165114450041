<template>
  <div>
    <el-select
      :value="selected"
      @input="dispatch"
      :placeholder="placeholder || $t('message.department')"
      filterable
      clearable
      :multiple="multiple"
      :size="size"
      :class="className + ' ' + 'd-block'"
    >
      <el-option
        v-for="(department, index) in items"
        :key="'departments-' + index"
        :label="department.name"
        :value="department.id"
      >
      </el-option>
    </el-select>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { i18n } from "@/utils/i18n";
export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    id: {
      default: null,
    },
    className: {
      type: String,
      default: null,
    },
    branch_id: {
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    id: {
      handler: async function () {
        setTimeout(() => {
          this.selected = this.id;
          this.changeBranch(this.id);
        });
      },
      deep: true,
      immediate: true,
    },
    branch_id: {
      handler: function (newVal, oldVal) {
        if (newVal != oldVal) {
          this.selected = null;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  mounted() {
    if (this.departments && this.departments.length === 0) {
      this.updateInventory();
    }
  },
  computed: {
    ...mapGetters({
      departments: "department/inventory",
    }),
    items: function () {
      return !this.branch_id
        ? this.departments
        : _.filter(this.departments, ["branch_id", this.branch_id]);
    },
  },
  methods: {
    ...mapActions({
      updateInventory: "department/inventory",
    }),
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
      this.changeBranch(e);
    },
    changeBranch(e) {
      let department = _.find(this.departments, ["id", e]);
      if (department) {
        this.$emit("updateBranch", department.branch_id);
      } else {
        this.$emit("updateBranch", false);
      }
    },
  },
};
</script>
