import request from '@/utils/request'

  export function store(data) {
    return request({
      url: '/assent/complaint',
      method: 'post',
      data
    })
  }

  export function index(id) {
    return request({
      url: `/assent/complaint/${id}`,
      method: 'get',
      
    })
  }

