import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter);

let routes = [{
        path: '/login',
        component: () => import('@/views/login/index'),
        hidden: true
    },
    
]

/**
 * Load all routes from modulea folder
 */
const request = require.context('./modules', true, /.js/);

request.keys().map(module => {
    routes = routes.concat(request(module).default);
});

const router = new VueRouter({
    routes: routes,
    mode: "history"
})

export default router;
