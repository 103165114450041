import Layout from '@/layouts/EmptyLayout'
export default [{
    path: '/complaint',
    name: 'complaint',
    meta: {
      layout: 'main'
    },
  component: () => import('@/views/complaint/index')
    
  }]
