import request from '@/utils/request'

  export function store(data) {
    return request({
      url: '/assent/evaluation',
      method: 'post',
      data
    })
  }

  export function index(id) {
    return request({
      url: `/assent/evaluation/${id}`,
      method: 'get',
    })
  }

