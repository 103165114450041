import request from '@/utils/request'


export function index(id) {
  	return request({
  	  url: `/additional_work/get-cache/${id}`,
  	  method: 'get',
  	})
  }  
  export function works(id) {
  	return request({
  	  url: `/additional_work/${id}`,
  	  method: 'get',
  	})
  }  

  export function reasons() {
  	return request({
  	  url: `/additional_work/reasons/get-all`,
  	  method: 'get',
  	})
  } 

  export function stafflist(params) {
  	return request({
  	  url: `/additional_work/staffs/all`,
  	  method: 'get',
      params        
  	})
  } 
  
  

  export function destroy(id) {
  	return request({
  	  url: `/additional_work/delete-cache/${id}`,
  	  method: 'delete',
  	})
  }  

  export function saveAll(data) {
  	return request({
  	  url: `/additional_work/save-all/${data.id}`,
  	  method: 'post',
      data
  	})
  }  
  