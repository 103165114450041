import request from '@/utils/request'

  export function index(id) {
  	return request({
  	  url: `/assent/sick-leave/${id}`,
  	  method: 'get',
       
  	})
  }  
  export function reason() {
  	return request({
  	  url: `/assent/sick-leaves/reasons`,
  	  method: 'get',
       
  	})
  } 
  export function staff(id) {
  	return request({
  	  url: `/assent/staff/${id}`,
  	  method: 'get',
       
  	})
  }  

  export function store(data) {
    return request({
      url: '/assent/sick-leave/store',
      method: 'post',
      data
    })
  }
