import Layout from '@/layouts/EmptyLayout'
export default [{
    path: '/login-auth',
    name: 'login_auth',
    meta: {
      layout: 'empty'
    },
    component: () => import('@/views/login/login_auth')
  }]

